<template>
  <div class="list2">
    <v-row>
      <v-col cols="2" v-for="item in list" :key="item.name_jp">
        <v-card outlined class="ma-2">
          <div :style="{ background: getPresenceBackGroundColor(item.presence_status), color: getPresenceColor(item.presence_status) }">
            <p style="padding-left: 10px; padding-top: 5px; margin-bottom: 0px;">{{ getPresenceName(item.presence_status) }}
              <v-tooltip bottom v-if="isMessage(item.message)">
              <template v-slot:activator="{ on,attrs }">
                <v-icon
                    :style="{color: getPresenceColor(item.presence_status)}"
                    v-bind="attrs"
                    v-on="on"
                >mdi-message-processing-outline</v-icon>
              </template>
              <span>{{ item.message }}</span>
            </v-tooltip>
            </p>
            <v-card-title style="padding-top: 1px; padding-bottom: 5px;">{{ item.name_jp }}</v-card-title>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div><v-icon>mdi-account-group</v-icon> {{ getGroupName(item.group_id) }}</div>
            <div><v-icon>mdi-map-marker</v-icon> <span v-if="getArrival(item)" style="color: red; font-weight: bold">出社予定</span><span v-else>自宅予定</span></div>
<!--            <div><v-icon>mdi-alarm-check</v-icon> {{ getTimestampToFormat(item.update_time.toDate()) }}</div>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import moment from "moment";

export default {
  name: "UserCardList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    today: "",
    shussya: "出社予定",
    jitaku: "自宅予定",
  }),
  created() {
    moment.locale( 'ja' );
    this.today = moment().format("YYYY/MM/DD");
  },
  methods: {
    getArrival(user) {
      if (user.arrival) {
        if (user.arrival.find(element => element === this.today)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    isMessage(message) {
      if (message) {
        return true;
      }
      return false;
    },
    getPresenceName(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.name;
        }
      }
    },
    getPresenceBackGroundColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.color;
        }
      }
    },
    getPresenceColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.font_color;
        }
      }
    },
    getGroupName(id) {
      for(let val of this.group) {
        if (val.id.toString() === id.toString()) {
          console.log("" + val.id.toString() + "/" + id.toString());
          return val.name;
        }
      }
    },
    getLocation(location) {
      if (location.toString() === "0") {
        return '自宅';
      } else if (location.toString() === "1") {
        return '出社';
      }
    },
    getTimestampToFormat(toDate) {
      const day = dayjs(toDate);
      return day.format('YYYY/MM/DD HH:mm:ss');
    }
  }
}
</script>

<style scoped>

.card-title {
  border-bottom: darkgray;
}

</style>