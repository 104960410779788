<template>
  <v-container fluid>
    <div class="text-h5">ダッシュボード</div>
    <v-divider class="ma-0"></v-divider>
    <v-row
        class="white"
    >
      <v-col cols="12">
        <v-card outlined class="">
          <v-card-title
              :style="{ background: getPresenceColor(this.currentUser.presence_status), color: getPresenceFontColor(this.currentUser.presence_status) }"
          >{{ this.currentUser.name_jp }} / {{ getGroupName(this.currentUser.group_id) }}</v-card-title>
          <v-divider light></v-divider>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                    class="presence-btn ma-1"
                    v-for="item in presence" :key="item.id"
                    tile
                    :style="{ background: item.color, color: item.font_color }"
                    @click="setPresence(item.id)"
                >
                  {{ item.name }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="message"
                    outlined
                    clearable
                    label="1行メッセージ"
                    type="text"
                >
                  <template v-slot:prepend>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      1行メッセージを保存することで、現在の状態を周囲に知らせることができます。
                    </v-tooltip>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn
                      @click="setMessage"
                    >保存</v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <UserCardList :list="getFilterData(this.currentUser.group_id)" :group="group" :presence="presence"></UserCardList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import firebase, {firebase_db} from "../../../plugins/firebase";
import UserCardList from "../../molecules/UserCardList";

export default {
  name: "Dashboard",
  components: {
    UserCardList
  },
  data: () => ({
    users : [],
    presence : [],
    group: [],
    currentUser: [],
    message: "",
  }),
  created() {
    firebase.onAuth();
    this.getCollectionUsers();
    this.getCollectionPresence();
    this.getCollectionGroups();
    this.getUserInfo();
  },
  mounted() {
    console.log('mounted:' + this.currentUser.name_jp);
  },
  methods: {
    getUserInfo() {
      let uid = firebase.auth().currentUser.uid;
      let collection = firebase_db.collection('users').where('uid', '==', uid);
      collection.onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          this.currentUser = doc.data();
          this.currentUser['docId'] = doc.id;
        })
        this.message = this.currentUser.message;
      });
    },
    getCollectionUsers() {
      let collection = firebase_db.collection('users').orderBy(`employee_id`)
      collection.onSnapshot(snapshot => {
        this.users = [];
        snapshot.forEach(doc => {
          this.users.push(doc.data())
        })
      });
    },
    getCollectionGroups() {
      firebase_db.collection('group').orderBy('sort').onSnapshot(snapshot => {
        this.group = [];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.group.push(tmpVar);
        })
      });
    },
    getCollectionPresence() {
      firebase_db.collection('presence').orderBy('sort').onSnapshot(snapshot => {
        this.presence = [];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.presence.push(tmpVar);
        })
      });
    },
    getGroupName(id) {
      if (id) {
        for(let val of this.group) {
          if (val.id.toString() === id.toString()) {
            return val.name;
          }
        }
      } else {
        return [];
      }
    },
    getPresenceColor(id) {
      if (id) {
        for(let val of this.presence) {
          if (val.id.toString() === id.toString()) {
            return val.color;
          }
        }
      } else {
        return "#FFFFFF";
      }
    },
    getPresenceFontColor(id) {
      if (id) {
        for(let val of this.presence) {
          if (val.id.toString() === id.toString()) {
            return val.font_color;
          }
        }
      } else {
        return "#000000";
      }
    },
    getFilterData(group_id) {
      if (group_id) {
        let user = this.users.filter(function (item) {
          if (group_id.toString() === item.group_id.toString()) {
            return true
          }
        }, group_id);

        //本社縛り( 山口あり )
        user = user.filter(function (item) {
          if (item.office_location === 1 || item.office_location === 2) {
            return true
          }
        });

        return user;
      } else {
        return [];
      }
    },
    setPresence(id) {
      firebase_db.collection("users").doc(this.currentUser.docId).update({
        presence_status: Number(id),
        update_time: firebase.firestore.Timestamp.now(),
      });
    },
    setMessage() {
      firebase_db.collection("users").doc(this.currentUser.docId).update({
        message: this.message,
        update_time: firebase.firestore.Timestamp.now(),
      });
    }
  }
}
</script>

<style scoped>

.presence-btn {
  width: 100px;
}

</style>